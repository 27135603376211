<app-new-top-search></app-new-top-search>
<div
	class="mask absolute w-full h-full z-[31] bg-black/30"
	[ngClass]="{
		visible: menuService.listMenuHoverState,
		invisible: !menuService.listMenuHoverState
	}"
></div>
<div class="h-[70vh]">
	<app-banner-carousel></app-banner-carousel>
</div>
<div class="lg:py-0 pb-5 lg:pb-5 lg:max-w-[1440px] mx-auto mt-10">
	<container-element *ngFor="let item of data" [ngSwitch]="item.kind">
		<app-main-standard
			*ngSwitchCase="'N'"
			[title]="item.name"
			[titleSuffix]="item.nameSuffix"
			[query]="item.link"
		></app-main-standard>
		<app-main-highlighted
			*ngSwitchCase="'B'"
			[title]="item.name"
			[titleSuffix]="item.nameSuffix"
			[query]="item.link"
		></app-main-highlighted>
		<app-main-compact
			*ngSwitchCase="'L'"
			[title]="item.name"
			[titleSuffix]="item.nameSuffix"
			[query]="item.link"
		></app-main-compact>
		<app-main-horizontal
			*ngSwitchCase="'H'"
			[title]="item.name"
			[titleSuffix]="item.nameSuffix"
			[query]="item.link"
		>
		</app-main-horizontal>
	</container-element>
</div>
